// Global style
import "./src/style/main.scss"
import "./src/style/responsive.scss"

export const onClientEntry = async () => {
  /* document.addEventListener("contextmenu", event => event.preventDefault())
  document.addEventListener("copy", e => {
    e.preventDefault()
  }) */
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}
